<template>
  <ValidationObserver v-slot="{ invalid }">
    <form id="form-login" class="form-horizontal form-control-borderless">
      <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"
                ><i class="fa fa-envelope-o" aria-hidden="true"></i
              ></span>
              <input
                v-model="email"
                autocomplete="off"
                type="email"
                name="login-username"
                class="form-control input-lg"
                placeholder="User Email"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider
        vid="password"
        rules="required|min:6|max:12"
        v-slot="{ errors }"
        tag="div"
      >
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
              <input
                v-model="password"
                type="password"
                name="login-password"
                class="form-control input-lg"
                placeholder="Password"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
              <input
                v-model="passwordConfirm"
                type="password"
                name="login-password-confirmation"
                class="form-control input-lg"
                placeholder="Password confirmation"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="numeric" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-coins"></i></span>
              <input
                v-model="sponsor"
                type="text"
                name="login-sponsor"
                class="form-control input-lg"
                placeholder="Sponsor"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group">
        <div class="col-xs-12 text-center">
          <button
            type="button"
            class="btn btn-sm btn-login"
            :disabled="invalid"
            @click="handleSignUp"
          >
            Register Account
          </button>
        </div>
      </div>
      <div class="form-group">
        <div class="col-xs-12 text-center">
          <router-link to="/signin" class="text-danger"
            ><p class="text-fogot" style="color:#f6e700">
              Already have an account?
            </p></router-link
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { signUp } from "@/services/auth";

export default {
  name: "SignUpForm",
  components: {},
  data: () => ({
    email: "",
    password: "",
    sponsor: "",
    token: "",
    passwordConfirm: ""
  }),
  mounted() {
    if (this.$route.query.sponsor) {
      this.sponsor = this.$route.query.sponsor;
    }
  },
  methods: {
    async handleSignUp() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      this.token = await this.$recaptcha("auth");
      const { data, status, message, errors } = await signUp(
        this.email,
        this.password,
        this.sponsor,
        this.token
      );
      this.$finishLoading();

      if (status == true) {
        this.$router.push({
          name: "signin"
        });
        this.$toast.success(message, {});
      } else if (status == false) {
        this.$toast.error(message, {});
      } else if (errors) {
        for (const property in errors) {
          let content = `${errors[property]}`;
          this.$toast.error(message, {});
        }
      } else {
        this.$toast.error(message, {});
      }
    },
    OnValidationPhone(payload) {
      this.phone = payload.formattedNumber;
    }
  }
};
</script>
<style scoped>
.vue-phone-number-input {
  position: relative;
  z-index: 10000;
}
.input-phone-number input:focus,
.input-phone-number input:hover,
.input-phone-number input:active {
  background-color: rgb(0 0 0 / 0.3) !important;
  color: #f78b00 !important;
  letter-spacing: 3px;
  font-weight: 700;
}
.country-selector__input,
.country-selector__list__item.selected {
  background-color: rgb(0 0 0 / 0.3) !important;
  color: #f78b00 !important;
  letter-spacing: 3px;
  font-weight: 700;
}
.input-tel__label,
.input-tel__input {
  color: black !important;
}
.country-selector__list {
  background-color: black !important;
}
.block {
  padding: 15px;
}
</style>
