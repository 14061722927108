<template>
  <auth-layout>
    <template #title>Sign Up</template>
    <sign-up-form></sign-up-form>
  </auth-layout>
</template>

<script>
import SignUpForm from "@/components/auth/SignUpForm";
import { isLoggedIn } from "@/services/auth";
import AuthLayout from "./layouts/Auth";

export default {
  name: "SignUp",
  components: {
    AuthLayout,
    SignUpForm
  },
  beforeCreate() {
    if (isLoggedIn()) this.$router.push({ name: "dashboard" });
  }
};
</script>
